import "./UserCollection.scss";

import React, { useState } from "react";

import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const CoachCollection = ({ handleCoach }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [certifications, setCertifications] = useState("");
  const [experience, setExperience] = useState("");
  const [expertise, setExpertise] = useState("");
  const [avatarUrl, setAvatarUrl] = useState([]);
  const [fileTooLarge, setFileTooLarge] = useState([]);
  const [id, setId] = useState("someUniqueId");

  const handleSubmit = (e) => {
    if (e.target.checkValidity()) {
      e.preventDefault();

      handleCoach(
        firstName,
        lastName,
        email,
        websiteUrl,
        phoneNumber,
        certifications,
        experience,
        expertise,
        avatarUrl
      );
    }
  };
  return (
    <div className="user-collection  ">
      <h2 className="">Contact Information</h2>
      <form
        onSubmit={(e) => {
          return handleSubmit(e);
        }}
      >
        <label className="required" htmlFor="first-name">
          First Name:
        </label>
        <input
          type="text"
          id="first-name"
          onChange={(e) => {
            return setFirstName(e.target.value);
          }}
          required
        />
        <label htmlFor="last-name">Last Name:</label>
        <input
          type="text"
          id="last-name"
          onChange={(e) => {
            return setLastName(e.target.value);
          }}
          required
        />
        <label className="required" htmlFor="email">
          Email:
        </label>
        <input
          type="email"
          id="email"
          onChange={(e) => {
            return setEmail(e.target.value);
          }}
          required
        />
        <h2 className="mt-3 ">Profile Information</h2>

        <hr />
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <label className="required" htmlFor="websiteUrl">
              Website:
            </label>
            <input
              type="text"
              id="websiteUrl"
              onChange={(e) => {
                return setWebsiteUrl(e.target.value);
              }}
              required
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <label className="required" htmlFor="phone-number">
              Phone Number:
            </label>
            <input
              type="tel"
              id="phone-number"
              onChange={(e) => {
                return setPhoneNumber(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <label className="required" htmlFor="certifications">
              Certfications:
            </label>
            <input
              type="text"
              id="certifications"
              onChange={(e) => {
                return setCertifications(e.target.value);
              }}
              required
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <label className="required" htmlFor="experience">
              Experience:
            </label>
            <input
              type="text"
              id="experience"
              onChange={(e) => {
                return setExperience(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className=" col-sm-12 col-md-6 col-lg-6">
            <label className="required" htmlFor="expertise">
              Expertise:
            </label>
            <input
              type="text"
              id="expertise"
              onChange={(e) => {
                return setExpertise(e.target.value);
              }}
              required
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <label className="required" htmlFor="avataUrl">
              Upload an image:
            </label>
            <input
              id={id}
              type="file"
              className=" btn btn-outline-dark "
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  let reader = new FileReader();
                  reader.onload = (ev) => {
                    const selectableMaxFileSize = 41208;

                    let file = e.target.files[0];
                    if (file.size > selectableMaxFileSize) {
                      setAvatarUrl(null);
                      return setFileTooLarge(
                        "Please upload a smaller size Image"
                      );
                    }
                    setFileTooLarge("");
                    return setAvatarUrl(ev.target.result);
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }
              }}
              required
              accept="image/*"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center">
            <p className="text-danger">{fileTooLarge}</p>
            {avatarUrl ? (
              <div className="row">
                <div className="small-9 small-centered columns">
                  <img
                    style={{ width: "295px" }}
                    className="thumbnail p-2"
                    src={avatarUrl}
                    alt=""
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <Button type="submit" variant="primary">
          Submit
        </Button>
      </form>
    </div>
  );
};

CoachCollection.propTypes = {
  handleCoach: PropTypes.func,
};

CoachCollection.defaultProps = {
  handleCoach: () => {},
};

export default CoachCollection;
