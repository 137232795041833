import React, { useEffect, useState } from "react";

import { Alert } from "react-bootstrap";
import BodyBox from "../../layouts/bodyBox";
import CoachCollection from "../../components/CoachCollection";
import LoadingOverlay from "../../components/LoadingOverlay";
import Quiz from "../../components/Quiz";
import SEO from "../../components/Seo";
import axios from "axios";
import { navigate } from "gatsby";

const MatchingOfCoach = () => {
  // Coach related
  const [displayCoachCollection, setDisplayCoachCollection] = useState(false);
  // Quiz related
  const [loadingQuiz, setLoadingQuiz] = useState(true);
  const [displayQuiz, setDisplayQuiz] = useState(false);
  const [quizData, setQuizData] = useState({});
  const [quizResponses, setQuizResponses] = useState([]);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    axios({
      method: "get",

      url: `${process.env.API_ENDPOINT}/matching/getCoachQuiz/1`,
      withCredentials: true,
    })
      .then((res) => {
        setQuizData(res.data);
        setDisplayQuiz(true);
      })
      .catch((err) => {
        setErrMsg("Error fetching questionaire");
        console.log(err);
        setErr(true);
        setDisplayQuiz(false);
      })
      .finally(() => {
        setLoadingQuiz(false);
      });
  }, []);

  const submitResponses = (choiceMap) => {
    setQuizResponses(Array.from(choiceMap));
    setDisplayQuiz(false);
    setDisplayCoachCollection(true);
  };
  const submitMatching = async (
    coachFirst,
    coachLast,
    coachEmail,
    websiteUrl,
    phoneNumber,
    certifications,
    experience,
    expertise,
    avatarUrl
  ) => {
    try {
      const coach = await axios({
        method: "post",
        data: {
          first_name: coachFirst,
          last_name: coachLast,
          email: coachEmail,
          website_url: websiteUrl,
          phone_number: phoneNumber,
          certifications,
          experience,
          expertise,
          avatar_url: avatarUrl,
        },

        url: `${process.env.API_ENDPOINT}/matching/updateOrCreateCoach`,
        withCredentials: true,
      });
      const quizProps = await axios({
        method: "post",
        data: {
          coach_id: coach.data.coach_id,
          quiz_id: quizData.quiz_id,
          choices: quizResponses,
        },

        url: `${process.env.API_ENDPOINT}/matching/submitCoachQuiz`,
      });
      navigate(
        `/onBoardCoaches/results?coach=${coach.data.coach_id}&attempt=${quizProps.data.attempt}`
      );
    } catch {
      setErrMsg("Error submitting quiz");
      setErr(true);
    }
  };

  return (
    <BodyBox>
      <SEO title="Get Matched" />
      {err ? <Alert variant="danger">{errMsg}</Alert> : null}
      <h1>Welcome New Coach</h1>
      {loadingQuiz ? <LoadingOverlay message="Loading Questionaire" /> : null}
      {displayQuiz ? (
        <Quiz
          questions={quizData.questionstocoaches}
          submitResponses={submitResponses}
        />
      ) : null}
      {displayCoachCollection ? (
        <div className="user-contact-form">
          <p className="text">
            Final step, after filling out the contact form.
          </p>
          <CoachCollection handleCoach={submitMatching} />
        </div>
      ) : null}
    </BodyBox>
  );
};

export default MatchingOfCoach;
